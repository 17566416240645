import { useEffect, useState } from "react";
import {BtnBack, IconAgent, IconLock, IconMoney, Group, RightAngle} from "../../assets/icons";
import {AppRoutes} from "../../Router";
import {useNavigate} from 'react-router-dom';
import "./MenuAgent.scss";
import "./ma.scss";
import {useIntl} from "react-intl";
import {useLoginContext} from "../../hooks/useLoginContext";
import ResponsiveAppBar from "../../components/shared/Menubar/ResponsiveAppBar";



export function MenuAgent() {
    const {user} = useLoginContext();
    const intl = useIntl();
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate(AppRoutes.CATALOG, {replace: true});
    };

    const handleSubmit1 = () => {
        navigate(AppRoutes.CHANGE, {replace: true});
    };
    
    const handleSubmit2= () => {
        navigate(AppRoutes.SOLICITUDES_DE_CREDITO, {replace: true});
    };
    
    const handleSubmit3= () => {
        navigate(AppRoutes.USUARIOS_PLATAFORM, {replace: true});
    };
    
    const handleSubmit4= () => {
        navigate(AppRoutes.CATALAGO_ADMINI, {replace: true});
    };
    
    const handleSubmit5= () => {
        navigate(AppRoutes.IMAGEN_CORPORTAVIA, {replace: true});
    };
    

    const [role, setRole] = useState('rolVendedor')
    return (
        <div>
            <ResponsiveAppBar/>
            <div className="flex-container-catalog">
                <div className="flex-item-right-catalog">
                </div>
                <div className="flex-item-left-catalog">
                </div>
            </div>
           
            {/* <div className="title-welcome">
                {`${intl.formatMessage({id: "welcome"})} ${" " + user.idUser}`}
                {}
            </div>
            <div className="subtitle-menu">
                {intl.formatMessage({id: "how_can_we_help_you_today"})}
            </div> */}


            {/* <div style={{margin:"auto"}}>

                    <IconLock className="vector-lock"/>
                    <div className="menu-text-change-password">
                        {intl.formatMessage({id: "change_of_password"})}
                    </div>
                    <div className="rectangle-change-password"  onClick={handleSubmit1}/>
                    
                    <div className="rectangle-menu-solicitud" />
                    <IconMoney className="vector-money"/>
                    <div className="menu-text" >
                        {intl.formatMessage({id: "credit_applications"})}
                    </div>
                    <div className="rectangle-menu-solicitud" onClick={handleSubmit2}/>

                    {
                        (user.idProfile!=='1' && user.idProfile!=='4') 
                        ?<>
                            <div className="menu-text-credit">
                                {intl.formatMessage({id: "manage_credit"})}
                            </div>
                            <IconAgent className="vector-agent"/>
                            <div className="rectangle-menu-credit" onClick={handleSubmit}/>
                        </>
                        :<></>
                    }
            </div> */}
                    
            <div className="menu-agent">
                <div className="title">{`${intl.formatMessage({id: "welcome"})} ${" " + user.idUser}`}</div>
                <div className="subtitle">{intl.formatMessage({id: "how_can_we_help_you_today"})}</div>
                <div className="items">
                    {
                        user.idProfile === '4'
                        ?<>
                            <div className="item" onClick={handleSubmit1}>
                                <IconLock/>
                                <div className="item-text">
                                    {intl.formatMessage({id: "change_of_password"})}
                                </div>
                            </div>
                            <div className="item" onClick={handleSubmit2}>
                                <IconMoney/>
                                <div className="item-text">
                                    {intl.formatMessage({id: "credit_applications"})}
                                </div>
                            </div>
                            <div className="item" onClick={handleSubmit}>
                                <IconAgent/>
                                <div className="item-text">
                                    {intl.formatMessage({id: "manage_credit"})}
                                </div>
                            </div>
                        </>
                        :<></>
                    }
                    {
                        user.idProfile === '3'
                        ?<>
                            {/* <div className="item" onClick={handleSubmit1}>
                                <IconLock/>
                                <div className="item-text">
                                    {intl.formatMessage({id: "change_of_password"})}
                                </div>
                            </div> */}
                            <div className="item" onClick={handleSubmit3}>
                                <Group/>
                                <div className="item-text" >
                                    Administracion de Usuarios
                                </div>
                            </div>
                            {/* <div className="item">
                                <IconLock/>
                                <div className="item-text">
                                    Cambio de Contraseña de Usuarios
                                </div>
                            </div> */}
                           {/*  <div className="item" onClick={handleSubmit4}>
                                <RightAngle/>
                                <div className="item-text">
                                   Administracion de catalogo
                                </div>
                            </div> */}
                            {/* <div className="item" onClick={handleSubmit5}>
                                <RightAngle/>
                                <div className="item-text">
                                    Administracion de imagen corporativa
                                </div>
                            </div> */}
                        </>
                        :<></>
                    }
                    {
                        user.idProfile === '1'
                        ?<>
                            <div className="item " onClick={handleSubmit1}>
                                <IconLock/>
                                <div className="item-text">
                                    {intl.formatMessage({id: "change_of_password"})}
                                </div>
                            </div>
                            <div className="item" onClick={handleSubmit2}>
                                <IconMoney/>
                                <div className="item-text">
                                    {intl.formatMessage({id: "credit_applications"})}
                                </div>
                            </div>
                        </>
                        :<></>
                    }
                </div>
            </div>
        </div>
    )
}
import {ArrowBack,Search} from "@mui/icons-material";
import {Fab} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Col, FormGroup, Input, Row} from "reactstrap";
import {AppRoutes} from "../../Router";
import './Catalog.scss';
import {inventario} from "../../__mocks__/data";
import {AdminService, WLOCreditAPIService} from "../../services";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import ClockLoader from "react-spinners/ClockLoader";
import {useLoginContext} from "../../hooks/useLoginContext";
import logoLinea from '../../assets/images/logoLinea.png';
import * as _ from 'lodash';
import {useRequestContext} from "../../hooks/useRequestContext";
import ResponsiveAppBar from "../../components/shared/Menubar/ResponsiveAppBar";

interface CatalogProps {
}

export function Catalog(_props: CatalogProps) {
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const [isLoading, setIsLoading] = useState(false);
    const [valorBusqueda, setBusqueda] = useState("");
    const [inventarioWLO, setInventarioWLO] = useState({} as any);
    const intl = useIntl();
    const [catalogo, setCatalogo] = useState([] as any);
    const navigate = useNavigate();

    const handleSubmit = () => {
        if(user.istipo=="2"){
            navigate(AppRoutes.LOGINAUTO, {replace: true});
        }else{
            navigate(AppRoutes.MENU_USER, {replace: true});
        }
    };

    const handleSubmitCredit = (codProd:any, name: any, price: any, imagen:any) => {
        setRequest({nameProduct: name, priceProduct: price, searchANI: false, errorConsulta: false,imagemoto:imagen})
        sessionStorage.setItem('CodProducto',codProd);
        codProd == 2 ? navigate(AppRoutes.POLICIES , {replace: true}) : navigate(AppRoutes.HOME, {replace: true});
    };

    useEffect(() => {
            fechInventary();
            // getInfoProductos({
            //     pws_tip_producto: "",
            //     pws_cod_producto: "",
            //     pws_cate_producto: ""
            // })
    }, [user]);

    const fechInventary = async () => {
        setIsLoading(true)
        const catalogosval=await WLOCreditAPIService.getInventario({
            pws_tip_producto: "1",
            pws_cod_producto: "",
            pws_cate_producto: ""
        })
        if(catalogosval.payload.data.RespProd){
            if(catalogosval.payload.data.RespProd.RespuestaProd.length >=1){
                setInventarioWLO(catalogosval.payload.data)
                setIsLoading(false)
            }else{
                let catalogoval =[
                {R_s_cod_prod: "1",R_s_nom_prod: "SEGUROS"},
                {R_s_cod_prod: "2",R_s_nom_prod: "LIBRE INVERSION"},]
                setIsLoading(false)
                setCatalogo(catalogoval)
            }

        }
        
        
        return catalogosval;
    }
    // const getInfoProductos = async (data:any)=>{
    //     setIsLoading(true)
    //     if(data.pws_cate_producto == "-1"){
    //         data.pws_cate_producto =""
    //     }
    //     const result = await AdminService.getlineascredito();
    //     try {
    //         if (_.isEmpty(result.payload.data.RespProd == null)) {
    //             setInventarioWLO(null);
    //         }
    //         setIsLoading(false)
    //     } catch (error) {
            
    //     }
    //     setInventarioWLO(result.payload.data);
    // }

    const handleSearch=async (evento:any)=>{
        setBusqueda(evento.target.value)
    }   
    // const searchbutton= async ()=>{
    //     getInfoProductos({
    //         pws_tip_producto: "",
    //         pws_cod_producto: "",
    //         pws_cate_producto: valorBusqueda
    //     })
    // }
    const renderInventaryLocal = () => {
        const localInventary = inventarioWLO;
        console.log(localInventary)
        const showInventary = localInventary && !isLoading;
        if (showInventary) {
            if (localInventary && !_.isEmpty(localInventary)) {
               return localInventary.RespProd.RespuestaProd.map((valor:any,key:any)=>{
                return (
                    <>
                    <Col md={1}/>
                        <Col
                            md={2}
                            onClick={() => handleSubmitCredit(valor.R_s_cod_prod, valor.R_s_nom_prod, valor.R_s_precio_comer,valor.R_s_link_img)}>
                            <img className={'img-fluid'} style={{height:"80%", borderRadius:"20px"}} src={valor.R_s_link_img} alt={''} />
                            <h1 className="title-model">{valor.R_s_nom_prod}</h1>
                        </Col>
                    <Col md={1}/>                           
                    </>
                )
            } )
                   
                
                
            }
        } else {
            return (<div>{'Cargando'}</div>);
        }
    }

    return (

        <div>
            <ResponsiveAppBar/>
            <div className={`${isLoading}spinner`}>
                <ClockLoader id='spinner' className="Spinner" color={"#00908e"} loading={isLoading} size={100}/>
            </div>
            <div className="fondo">
            <div className="flex-container-catalog">
                <div className="flex-item-left-catalog">
                    <Fab variant="extended" color="neutral" aria-label="add"
                         onClick={handleSubmit} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        {intl.formatMessage({id: "button_back"})}
                    </Fab>
                </div>
                <div className="flex-item-right-catalog">
                    <h1 className="title-motos-catalog">{intl.formatMessage({id: "why_your_cred"})}</h1>
                    <h3 className="subtitle-motos-catalog">{intl.formatMessage({id: "choose_the_one_of_your_preference"})}</h3>
                </div>
                <div className="flex-item-left-catalog">
                </div>
            </div>
            <Row className="flex-item-right-catalog">
                <Col md={6}>
                <FormGroup>
                        {/* <div style={{display:"flex"}}>
                            <label>Filtro de busqueda por categorias</label>
                        <Input
                            className='form-control-search-catalog'
                            id="name"
                            name="name"
                            onBlur={handleSearch}
                            placeholder={intl.formatMessage({id: "do_search_catalog"})}
                            type="select"
                        >
                            <option value="">Selecciona una categoria</option>
                        {catalogo.map((valor:any)=>{
                            return(<option value={valor.R_s_cod_cate}>{valor.R_s_nom_cate}</option>)
                        })}
                            </Input>
                            <button onClick={searchbutton} className="buttonSearch">
                            <Search />
                            </button>
                        </div> */}
                       
                    </FormGroup>
                </Col>
                <Col md={6}/>
                {renderInventaryLocal()}
            </Row>
            </div>
        </div>
    )
}

